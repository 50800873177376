<template>
    <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">
        <button class="pattenbutton" @click="showpanel">패턴분석기</button>
        <div class="pattenwrap_powerball" :class="{'active':isshow}">
            <div class="typebuttons">
                <button @click="change(1)" :class="{'bactive':currenttap == 1}">출발패턴</button>
                <button @click="change(2)" :class="{'bactive':currenttap == 2}">줄수패턴</button>
                <button @click="change(3)" :class="{'bactive':currenttap == 3}">홀짝패턴</button>
<!--                <button @click="change(4)" :class="{'bactive':currenttap == 4}">출줄패턴</button>-->
            </div>
            <div class="percent">
                <span v-if="currenttap == 1">
                    <span style="color: lightskyblue">좌({{sadali['leftorright_left_count']}} 회 , {{sadali['leftorright_left_percent']}}%)</span>
                    <span style="color: red;margin-left: 5px">우({{sadali['leftorright_right_count']}} 회 , {{sadali['leftorright_right_percent']}}%)</span>
                </span>
                <span v-if="currenttap == 2">
                    <span style="color: lightskyblue">3줄({{sadali['linecount_line3_count']}} 회 , {{sadali['linecount_line3_percent']}}%)</span>
                    <span style="color: red;margin-left: 5px">4줄({{sadali['linecount_line4_count']}} 회 , {{sadali['linecount_line4_percent']}}%)</span>
                </span>
                <span v-if="currenttap == 3">
                    <span style="color: lightskyblue">홀({{sadali['oddeven_odd_count']}} 회 , {{sadali['oddeven_odd_percent']}}%)</span>
                    <span style="color: red;margin-left: 5px">짝({{sadali['oddeven_even_count']}} 회 , {{sadali['oddeven_even_percent']}}%)</span>
                </span>
            </div>
            <div class="panel">
                <div v-for="ball in sadali_leftorright" class="typepanel" v-if="currenttap == 1">
                    <div v-if="Object.keys(ball)[0] == '좌'" class="title t1">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '우'" class="title t2">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '좌'" class="b b1" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '우'" class="b b2" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>
                </div>

                <div v-for="ball in sadali_linecount" class="typepanel" v-if="currenttap == 2">
                    <div v-if="Object.keys(ball)[0] == '3줄'" class="title t1">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '4줄'" class="title t2">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '3줄'" class="b b1" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '4줄'" class="b b2" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>
                </div>

                <div v-for="ball in sadali_oddeven" class="typepanel" v-if="currenttap == 3">
                    <div v-if="Object.keys(ball)[0] == '홀'" class="title t1">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '짝'" class="title t2">
                        {{Object.keys(ball)[0]}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '홀'" class="b b1" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>
                    <div v-if="Object.keys(ball)[0] == '짝'" class="b b2" v-for="item in ball[Object.keys(ball)[0]]">
                        {{item}}
                    </div>
                </div>


            </div>
        </div>

    </div>

</template>

<script>
    import {getParrents} from "../../network/leisureRequest";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureGamePattenSadaliComp",
        props: {
            kind: {
                type: Number,
                default() {
                    return 0;
                }
            },
        },
        data() {
            return {
                pattenMap: null,
                sadali:[],
                sadali_oddeven: [],
                sadali_linecount: [],
                sadali_leftorright: [],
                isshow:false,
                currenttap:1
            }
        },
        methods: {
            change(ct){
                this.currenttap = ct;
            },
            pattens() {
                getParrents(this.kind).then(res => {
                    this.pattenMap = res.data.data;
                    this.sadali = this.pattenMap['sadali'];
                    this.sadali_oddeven = this.sadali['oddeven']
                    this.sadali_leftorright = this.sadali['leftorright']
                    this.sadali_linecount = this.sadali['linecount']
                })
            },
            showpanel(){
                this.isshow =!this.isshow
                if(this.isshow){
                    this.pattens()
                }
            }
        },
        created() {
            this.pattens()

        }
    }
</script>

<style scoped>
    .bactive{
        background-color: #000000;
    }
    .pattenbutton {
        width: 100%;
        height: 32px;
        background-color: #262626;
    }
    .pattenwrap_powerball{
        width: 100%;
        display: none;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
    .pattenwrap_powerball .typebuttons{
        width: 100%;
        display: flex;align-items: center;justify-content: space-between;
        flex-wrap: nowrap;
        margin-top: 1px;
    }
    .pattenwrap_powerball .typebuttons button{
        width: 33.2%;
        height: 32px;
        border: 1px solid #5c5c5c;
    }
    .pattenwrap_powerball .percent{
        width: 100%;
        display: flex;align-items: center;justify-content: center;
        flex-wrap: nowrap;
        margin-top: 1px;
        height: 32px;
    }
    .panel {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        padding: 2px;
        width: 100%;
        overflow: scroll;
        margin-top: 5px;
        max-height: 400px;
    }

    .panel .typepanel {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 5px;
        font-size: 10px;
    }

    .typepanel .title {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .typepanel .t1 {
        color: lightskyblue;
    }

    .typepanel .t2 {
        color: red;
    }

    .typepanel .b {
        width: 30px;
        height: 30px;
        margin: 1px 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .typepanel .b1 {
        background-color: lightskyblue;
    }

    .typepanel .b2 {
        background-color: red;
    }
    .active{
        display: flex!important;
    }
</style>